<template>
  <div class="link">
    <div class="link-wrap">
      <div class="logo-wrap">
        <img src="../assets/images/icon.png">
        <h1>枇杷村</h1>
      </div>
      <div class="content-wrap">
        <div class="content">
          <h3>即将离开枇杷村，请仔细核对链接地址，谨防网络诈骗！</h3>
          <p>{{$route.query.target}}</p>
          <p class="tip">站长提示：玩腻了记得回来哦～</p>
        </div>
        <div class="opera">
          <Button @click="handleLink" type="solid" theme="function" size="big">继续访问</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleLink () {
      window.location.href = this.$route.query.target
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/variables";
.link {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .link-wrap {
    width: 700px;
    margin-top: -150px;
    .logo-wrap {
      background-color: transparent;
      display: flex;
      margin-bottom: 10px;
      h1 {
        font-weight: normal;
        font-size: 20px;
        margin: 0 0 0 5px;
      }
      img {
        width: 30px;
      }
    }
    .content-wrap {
      background-color: #fff;
      padding: 40px 40px 20px 40px;
      .content {
        padding-bottom: 20px;
        h3 {
          font-size: 18px;
          font-weight: normal;
          margin: 0 0 20px 0;
        }
        p {
          margin: 0;
          line-height: 25px;
          &:first-of-type {
            background-color: $background-color-second;
            color: #666;
            padding: 5px 10px;
            border-radius: 5px;
            word-break: break-all;
            line-height: 20px;
            font-size: 16px;
          }
          &.tip {
            color: $font-color-third;
            margin-top: 5px;
            // text-decoration: underline;
          }
        }
      }
      .opera {
        border-top: 1px solid #eee;
        text-align: right;
        padding: 20px 0 0 0;
      }
    }
  }
}
</style>
